import React, {useState} from "react";
import './cabecalho.css'
import LogoIMG from '../imagens/logo.png'
import PCImg from '../imagens/pc-imagem.jpg'
import PCImgPrin from '../imagens/logo-principal.png'
import PCImgNome from '../imagens/logo-nome.png'


const Cabecalho = (props) => {
    const [InputCheck, setInputCheck] = useState()
    
    const [MenuRootDiv, setMenuRootDiv] = useState()
    const [MenuRootDivObject, setMenuRootDivObject] = useState()
    const [ShowDivSegmentos, setShowDivSegmentos] = useState(false)
    

    const ChangeInputMenuCheck = (value) => {
        /* Função que escuta a movimentação do checkbuttom */ 
        let new_value = value.currentTarget.checked
       
        if (new_value){
            
            MenuRootDiv.style.display = 'block'
        }else {
            MenuRootDiv.style.display = 'none'
        }
    }
    window.document.addEventListener('scroll', () => {
        if (window.scrollY > 0){
            MenuRootDivObject.style.background = 'rgb(38, 119, 0)'
        }else {
            MenuRootDivObject.style.background = 'none'
        }
    });

    return (
        <>
       
        <nav id={props.id}>
            <div id='div-root-cabecalho' ref={setMenuRootDivObject}>
                <div id='div-contem-img-logo' onClick={() => window.location.href = '/'}>
                    <img src={PCImgPrin} id='img-pring-div'/>
                    <img src={PCImgNome} id='img-nome-div'/>
                </div>
                <div id='div-menu-hambuger'>
                    <input type = "checkbox" id = "input-menu" onChange={(value) => ChangeInputMenuCheck(value)}/>
                    <label for = "input-menu" id='icon-menu-hamburger'>&#9776;</ label>
                </div>
                <div id='div-menu-root' className="menu-div">
                    
                    <div id='options-menu'>
                        {/*
                    <li><a href="/" class='options-menu-itens'>Início</a></li>
                    
                        <li><a class='options-menu-itens'>Segmentos</a>
                            <ul>
                                <li><a href="#">Mercados</a></li>
                                
                            </ul>
                        </li>
                    <li><a class='options-menu-itens' href="/sobre">Sobre</a></li>
                    <li><a class='options-menu-itens'>Contato</a></li>
    */}
                    
                    {/*
                        <a href="/" class='options-menu-itens'>Início</a>
                        <a class='options-menu-itens'>Segmentos</a>
                        <div className="submenu-options-menu-itens">
                            <a>teste</a>
                        </div>
                        <a class='options-menu-itens' href="/sobre">Sobre</a>
                        <a class='options-menu-itens'>Contato</a>
    */}             
                    <div class='options-menu-itens-div'><a href="/" class='options-menu-itens'>Início</a></div>
                    <div class='options-menu-itens-div' onMouseOver={() => setShowDivSegmentos(true)} onMouseOut={() => setShowDivSegmentos(false)}>
                        <a class='options-menu-itens' >Segmentos</a>
                        <div className={`options-submenu options-submenu-show-${ShowDivSegmentos}`}>
                            <a href="/materiaisdeconstrucao">Materiais de Construção</a>
                            <a href="/supermercado">Supermercados   </a>
                            <a href="/autopecas">Autopeças</a>
                            
                        </div>
                    </div>
                    <div class='options-menu-itens-div'><a class='options-menu-itens' href="/sobre">Sobre</a></div>
                    <div class='options-menu-itens-div'><a class='options-menu-itens' href='/algunsclientes'>Alguns de Nossos Clientes</a></div>
                    
                    </div>
                </div>
                <div id='div-menu-root-hambuguer' className="menu-div">
                    
                    <div id='options-menu-hambuguer' ref={setMenuRootDiv} onLoad={() => MenuRootDiv.style.display = 'none'}>
                
                        <a class='options-menu-itens' href="/">Início</a>
                        <a class='options-menu-itens' href="/materiaisdeconstrucao">Segmentos</a>
                        <div id='div-cabecalho-hamb'>
                            <a href="/materiaisdeconstrucao">Materiais de Construção</a>
                            <a href="/supermercado">Supermercados   </a>
                            <a href="/autopecas">Autopeças</a>
                        </div>
                        <a class='options-menu-itens' href="/sobre">Sobre</a>
                        <a class='options-menu-itens' href='/algunsclientes'>Alguns de Nossos Clientes</a>
                        
                        
                    </div>
                </div>
                
            </div>
        </nav>
       

        
        </>
    )
}

export default Cabecalho