import React, {useState} from "react";
import './materiais.css'
import IMGQuadro from '../imagens/01.jpg'
import IMGCheck from '../imagens/icon_check_without_bg.webp'
import IMGMateriais from '../imagens/02.jpg'
import IMGMateriais2 from '../imagens/03.png'
import IMGMoldeNotebook from '../imagens/notebook.png'
import IMGNotebook from '../imagens/cotacao-notebook.jpg'
import IMGCellMolde from '../imagens/cellM.png'
import IMGCell from '../imagens/cell-img.jpg'
import IMGCurvaABC from '../imagens/curva-abc.jpg'
import IMGCellPc from '../imagens/pc-e-cell.png'
import IMGDRE from '../imagens/dre.jpg'
import Cabecalho from "../cabecalho/cabecalho";

const Materiais = (props) => {
    const [CheckNfe, setCheckNfe] = useState([
        'Informações de vendas de produtos no período',
        'Informações de compras de produtos no período',
        'Informações de custo dos produtos para facilitar suas compras'
    ])
    const [Contacao, setContacao] = useState([
        'Melhor controle dos preços ofertados à seus clientes',
        'Visualização de todos os orçamentos pendentes',
        'Efetivação de orçamentos em vendas com apenas 1 clique'
    ])
    return (
        <>
        <Cabecalho/>
        <div id='div-materiais-root'>
            <div id='materiais-div-img'>
                
            </div>
            <div className="div-info-materiais-line div-info-materiais-line-color2">
                <div className="div-info-materiais-low-left div-info-materiais-low-left2">
                    <h1>Materiais de Construção</h1>
                    <h2>Soluções inteligentes para sua empresa</h2>
                </div>
                <div className="div-info-materiais-low-right">
                    
                        <img src={IMGQuadro} className='imgens-lines '/>
                  
                    
                    
                        
                    
                </div>
            </div>
            <div className="div-info-materiais-line div-info-materiais-line-color">
                <div className="div-info-materiais-low-left">
                    <h1>Análise e Sugestão de Compras</h1>
                    {
                        CheckNfe.map((item) => 
                        <div className="list-check-materiais">
                            <img src={IMGCheck}/>
                        <label>{item}</label>
                    </div>
                        )
                    }
                </div>
                <div className="div-info-materiais-low-right">
                    
                        <img src={IMGMateriais} className='imgens-lines imgens-lines-box'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-materiais-line">
                <div className="div-info-materiais-low-left">
                    <h1>Orçamento </h1>
                    {
                        Contacao.map((item) => 
                        <div className="list-check-materiais">
                            <img src={IMGCheck}/>
                        <label>{item}</label>
                    </div>
                        )
                    }
                </div>
                <div className="div-info-materiais-low-right">
                    
                        <img src={IMGMateriais2} className='imgens-lines imgens-lines-box'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-materiais-line div-info-materiais-line-color">
                <div className="div-info-materiais-low-left">
                    <h1>Curva ABC</h1>
                    
                </div>
                <div className="div-info-materiais-low-right">
                    
                        <img src={IMGCurvaABC} className='imgens-lines imgens-lines-box' id='materiais-img-abc'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-materiais-line div-info-materiais-line22">
                <div className="div-info-materiais-low-left">
                    <h1>DRE</h1>
                    
                </div>
                <div className="div-info-materiais-low-right">
                    
                        <img src={IMGDRE} className='imgens-lines  imgens-lines-box' id='imgens-lines-dre'/>
                  
                    
                    
                        
                    
                </div>
            </div>
            
        </div>
        <div className='div-rodape'>
          <label>COPYRIGHT &copy; 2025 MegaSoftware Software House, Todos os direitos reservados. Fones: (67) 9 8111-6008 (67) 9 8162-5775</label>
       </div>
        </>
    )
}

export default Materiais