import axios from "axios"

export class ConnectionCotacao {
    constructor() {
        this.url = "https://automacaomega.com.br" //dominio principal da api

        /*APIs referenciadas*/
        this.locais = {
            "intranet":"intranet/api"
        }
    }

    async conn(local, api, dados){
        /*Funcao que faz a comunicacao com a API*/
        
        let resposta = await axios.post(`${this.url}/${this.locais[local]}/${api}`, dados, {headers:{
            'Content-Type': 'application/json'
        }})
        
      

        if (resposta.status != 200){
            resposta = false
        } else if (resposta.data.status == '1'){
            alert(`!!ERRO-DEV: ${resposta.data.mot}`)
        }

        return resposta.data
    }

    async Intranet(api, dados){
        /*FUncao que envia o pedido para intrenet*/
      
        var retorno = await this.conn(
            'intranet', //local
            api, //api
            dados
        )

        return retorno
    }
}