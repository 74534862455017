import React, { useEffect, useState } from "react";
import './style.css'
import Logo from '../../imagens/logo-nome.png'
import LogoIMG from '../../imagens/logo-principal.png'
import LogoMega from '../../imagens/logo-mega.jpg'
import { Connection } from "../connect";
import Load from '../../imagens/load_gif.gif'
import InputMask from 'react-input-mask';
import { ConnectionCotacao } from "../connect";

export const HomeCotacao = () => {
    const [ ListaCotacoes, setListaCotacoes ] = useState([])
    const [ NomeEstabelecimento, setNomeEstabelecimento ] = useState('')
    const [ Vendedor, setVendedor ] = useState('')

    const Pesquisar = async () => {
        const conn = new ConnectionCotacao()
        let dados_env = {
           'token': localStorage.getItem('token')
            
        }
        let resp = await conn.Intranet('cotacao/consulta_cotacao', dados_env)
        if (resp){
            
            if (resp.status == '0'){
                setListaCotacoes([...resp.cotacoes])
                setVendedor(resp.vendedor)
                setNomeEstabelecimento(resp.estabelecimento)
            }
            if (resp.status == '2'){
                window.location.href = '/cotacao/login'
            }
        }
    }

    useEffect(() => {Pesquisar()}, [])

    return (
        <>
        <div className="logo-topo-mega">
            <img src={LogoMega}/>
            <div className="telefone">
            <label>{"(34) 99642-9575 "}</label>
            <label>{"(67) 98111-6008"}</label>
            </div>
        </div>
        <div id="div-home-cotacao">
            <div id="div-home-cotacao-content">
                <div className="button-voltar" onClick={() => window.location.href = '/cotacao/login'}> {"< login"}</div>
                <h1 id='sup-home-cotacao'>Olá, {Vendedor}!</h1>
                <p>Abaixo estão as cotações referentes ao estabelecimento <strong>{NomeEstabelecimento}</strong>.</p>
                <div>
                    {
                        ListaCotacoes.map((item) => (
                            <div className="lista-cotacoes" onClick={
                                () => window.location.href = `/cotacao/produtos/${item.id}`
                            } style={{
                                
                            }}>
                                <div style={{display: 'inline-block', width: '25%'}}>
                                    {item.data}
                                </div>
                                <div style={{display: 'inline-block', width: '25%'}}>
                                    {item.status == '1' ? 'Em Aberto' : 'Encerrada' }
                                </div>
                                <div style={{display: 'inline-block', width: '25%'}}>
                                    {item.qtd_produtos} produtos
                                </div>
                                <div style={{display: 'inline-block', width: '25%'}}>
                                    <strong>{item.finalizado ? 'Cotação Enviada': item.status == '1' ? 'Ainda não Enviada' : "Não Enviada"}</strong>
                                </div>
                            </div>
                            
                        ))
                    }
                </div>
            </div>
            

        </div>

        <div id="div-home-cotacao-mobile">
            <div id="div-home-cotacao-content-mobile">
            <div className="button-voltar" onClick={() => window.location.href = '/cotacao/login'}> {"< login"}</div>
                <h1 id='sup-home-cotacao-mobile'>Olá, {Vendedor}!</h1>
                <p>Abaixo estão as cotações referentes ao estabelecimento <strong>{NomeEstabelecimento}</strong>.</p>
                <div>
                    {
                        ListaCotacoes.map((item) => (
                            <div className="lista-cotacoes-mobile" onClick={
                                () => window.location.href = `/cotacao/produtos/${item.id}`
                            }style={{
                                
                            }}>
                                <div style={{display: 'inline-block', width: '30%'}}>
                                    {item.data}
                                </div>
                                <div style={{display: 'inline-block', width: '20%'}}>
                                    {item.status == '1' ? 'Em Aberto' : 'Encerrada' }
                                </div>
                                <div style={{display: 'inline-block', width: '25%'}}>
                                    {item.qtd_produtos} itens
                                </div>
                                <div style={{display: 'inline-block', width: '25%'}}>
                                    <strong>{item.finalizado ? 'Cotação Enviada': 'Ainda não Enviada'}</strong>
                                </div>
                            </div>
                            
                        ))
                    }
                </div>
            </div>
            

        </div>
        </>
    )
}