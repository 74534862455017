import React, {useState} from "react";
import './algunsclientes.css'
import IMGQuadro from '../imagens/img_blocks.webp'
import IMGCheck from '../imagens/icon_check_without_bg.webp'
import IMGSupemercadoNfe from '../imagens/atopeca02.jpg'
import IMGMoldeNotebook from '../imagens/notebook.png'
import IMGNotebook from '../imagens/cotacao-notebook.jpg'
import IMGCellMolde from '../imagens/cellM.png'
import IMGCell from '../imagens/cell-img.jpg'
import IMGCurvaABC from '../imagens/curva-abc.jpg'
import IMGCellPc from '../imagens/pc-e-cell.png'
import IMGDRE from '../imagens/dre.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Cliente1IMG from '../imagens/LOGOS/001 Supermerado Master - Cassilandia - MS.jpg'
import Cliente2IMG from '../imagens/LOGOS/001 Supermercado Bom Preco - Cassilandia - MS.jpg'
import Cliente3IMG from '../imagens/LOGOS/001 Supermercado do Edinho - Cassilandia - MS  .jpeg'
import Cliente4IMG from '../imagens/LOGOS/001 Supermercado Ideal - Cassilandia - MS.jpg'
import Cliente5IMG from '../imagens/LOGOS/001 Supermercado Mix - Cassilandia - MS.jpg'
import Cliente6IMG from '../imagens/LOGOS/001 Supermercado Pantanal - Cassilandia - MS.jpg'
import Cliente7IMG from '../imagens/LOGOS/002 Supermercado Santana - Paranaiba - MS.jpg'
import Cliente8IMG from '../imagens/LOGOS/003 Mercado Martins - Inocencia - MS.jpg'
import Cliente9IMG from '../imagens/LOGOS/003 Mini Mercado Estrela - Inocencia - MS.jpg'
import Cliente10IMG from '../imagens/LOGOS/004 Emporio Bom Preco - Aparecida do Taboado - MS.jpg'
import Cliente11IMG from '../imagens/LOGOS/004 Mini Mercado Cidade Nova - Aparecida do Taboado - MS.jpg'
import Cliente12IMG from '../imagens/LOGOS/004 Supermercado Gianini - Aparecida do Taboado - MS.jpg'
import Cliente13IMG from '../imagens/LOGOS/005 Supermercado VIP - Iturama - MG.jpg'
import Cliente14IMG from '../imagens/LOGOS/006 Supermercado Massuia - Sao Sebastiao do Pontal - MG.jpeg'
import Cliente15IMG from '../imagens/LOGOS/007 Mini Mercado Ponto da Economia - Honoropolis - MG.jpg'
import Cliente16IMG from '../imagens/LOGOS/008 Mini Mercado Favorito - Uniao de Minas - MG.bmp'
import Cliente17IMG from '../imagens/LOGOS/009 Mercearia Super Cestas - Chapadao do Ceu - GO.jpg'
import Cliente18IMG from '../imagens/LOGOS/010 Mercado Jordao - Tres Lagoas - MS.jpg'
import Cliente19IMG from '../imagens/LOGOS/011 Loja Elly Ella - Cassilandia - MS.jpeg'
import Cliente20IMG from '../imagens/LOGOS/011 Rafaela Calcados - Cassilandia - MS.jpeg'
import Cliente21IMG from '../imagens/LOGOS/011 Rafaela Chinelaria - Cassilandia - MS.jpg'
import Cliente22IMG from '../imagens/LOGOS/011 Valentina Variedades - Cassilandia - MS.jpg'
import Cliente23IMG from '../imagens/LOGOS/011 Visage Cosmeticos - Cassilandia - MS.jpg'
import Cliente24IMG from '../imagens/LOGOS/011 Vitoria Cosmeticos - Cassilandia - MS.jpeg'
import Cliente25IMG from '../imagens/LOGOS/012 Chocoplast - Tres Lagoas - MS.jpg'
import Cliente26IMG from '../imagens/LOGOS/013 Refrugeralcao Refrimaq - Chapadao do Sul - MS.jpg'
import Cliente27IMG from '../imagens/LOGOS/014 Royal Bull - Chapadao do Ceu - GO.jpg'
import Cliente28IMG from '../imagens/LOGOS/PagueMenos.jpg'

import Cabecalho from "../cabecalho/cabecalho";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();


const AlgunsCliente = (props) => {
    const [CheckNfe, setCheckNfe] = useState([
        'Emite NFC-e',
        'Integrado com PIX',
        'Integrado com TEF (Transferência Eletrônica de Fundos)'
    ])
    const [Contacao, setContacao] = useState([
        'O fornecedor pode enviar seus preços por celular, notebook e desktop onde tiver internet',
        'Obtendo mais rápido possível os custos das mercadorias',
        'Obtendo o resultado com maior rapidez'
    ])
    const [ImagensCar, setImagensCar] = useState([
        [Cliente1IMG, ' Supermercado Master ', ' Cassilândia  -  MS'],[Cliente2IMG, ' Supermercado Bom Preço ', ' Cassilândia  -  MS'],[Cliente3IMG, ' Supermercado do Edinho ', ' Cassilândia  -  MS  '],[Cliente4IMG, ' Supermercado Ideal ', ' Cassilândia  -  MS'],[Cliente5IMG, ' Supermercado Mix ', ' Cassilândia  -  MS'],[Cliente6IMG, ' Supermercado Pantanal ', ' Cassilândia  -  MS'],[Cliente7IMG, ' Supermercado Santana ', ' Paranaíba  -  MS'],[Cliente8IMG, ' Mercado Martins ', ' Inocência  -  MS'],[Cliente9IMG, ' Mini Mercado Estrela ', ' Inocência  -  MS'],[Cliente10IMG, ' Empório Bom Preço ', ' Aparecida do Taboado  -  MS'],[Cliente11IMG, ' Mini Mercado Cidade Nova ', ' Aparecida do Taboado  -  MS'],[Cliente12IMG, ' Supermercado Gianini ', ' Aparecida do Taboado  -  MS'],[Cliente13IMG, ' Supermercado VIP ', ' Iturama  -  MG'],[Cliente14IMG, ' Supermercado Massuia ', ' São Sebastião do Pontal  -  MG'],[Cliente15IMG, ' Mini Mercado Ponto da Economia ', ' Honorópolis  -  MG'],[Cliente16IMG, ' Mini Mercado Favorito ', ' União de Minas  -  MG'],[Cliente17IMG, ' Mercearia Super Cestas ', ' Chapadão do Céu  -  GO'],[Cliente18IMG, ' Mercado Jordão ', ' Três Lagoas  -  MS'],[Cliente28IMG, 'Mercearia Pague Menos', 'Itajá - GO'], [Cliente19IMG, ' Loja Elly Ella ', ' Cassilândia  -  MS'],[Cliente20IMG, ' Rafaela Calçados ', ' Cassilândia  -  MS'],[Cliente21IMG, ' Rafaela Chinelaria ', ' Cassilandia  -  MS'],[Cliente22IMG, ' Valentina Variedades ', ' Cassilândia  -  MS'],[Cliente23IMG, ' Visage Cosméticos ', ' Cassilândia  -  MS'],[Cliente24IMG, ' Vitória Cosméticos ', ' Cassilândia  -  MS'],[Cliente25IMG, ' Chocoplast ', ' Três Lagoas  -  MS'],[Cliente26IMG, ' Refrigeração Refrimaq ', ' Chapadão do Sul  -  MS'],[Cliente27IMG, ' Royal Bull ', ' Chapadão do Céu  -  GO'],
    ])
    const [ObjectDiv, setObjectDiv] = useState()

    function ContarSegundos(){
        var segundos = 120;
        ObjectDiv.scroll(ObjectDiv.scrollLeft + 1000, 0)
    }
    
    

    const Next = () => {
        console.log(ObjectDiv.scrollLeft)

        ObjectDiv.scroll(ObjectDiv.scrollLeft + 420, 0)
        
    }
    const Ant = () => {
        console.log(ObjectDiv.scrollLeft)
        ObjectDiv.scroll(ObjectDiv.scrollLeft - 420, 0)
        
    }
    return (
        <>
        <Cabecalho/>
        <div id='div-algunsclientes-root' onLoad={() => setInterval(ContarSegundos, 120000)}>
            <div id='algunsclientes-div-img'>
                
            </div>
            <div id='algunsclientes-div-img-titulo'>
                <p>Alguns de Nossos Clientes</p>
            </div>
            
            <div id="testDiv" ref={setObjectDiv}>
                <div className="car-prox-ant" id='seta-ant' onClick={Ant}>
                    <p>{'<'}</p>
                </div>
               {
                ImagensCar.map((img) => (
                    <div class='testimgdiv'>
                        <img class="testimg" src={img[0]} />
                        <p>{img[1]}</p>
                        <p>{img[2]}</p>
                    </div>
                ))
               }
               <div className="car-prox-ant" id='seta-pro' onClick={Next}>
                    <p>{'>'}</p>
                </div>
            </div>
        </div>
        <div className='div-rodape'>
          <label>COPYRIGHT &copy; 2025 MegaSoftware Software House, Todos os direitos reservados. Fones: (67) 9 8111-6008 (67) 9 8162-5775</label>
       </div>
        </>
    )
}

export default AlgunsCliente