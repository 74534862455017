import React, {useState} from "react";
import './autopecas.css'
import IMGQuadro from '../imagens/01.jpg'
import IMGCheck from '../imagens/icon_check_without_bg.webp'
import IMGautopecas from '../imagens/atopeca01.jpg'
import IMGautopecas2 from '../imagens/atopeca02.jpg'
import IMGMoldeNotebook from '../imagens/notebook.png'
import IMGNotebook from '../imagens/cotacao-notebook.jpg'
import IMGCellMolde from '../imagens/cellM.png'
import IMGCell from '../imagens/cell-img.jpg'
import IMGCurvaABC from '../imagens/curva-abc.jpg'
import IMGCellPc from '../imagens/pc-e-cell.png'
import IMGDRE from '../imagens/dre.jpg'
import Cabecalho from "../cabecalho/cabecalho";

const AutoPecas = (props) => {
    const [CheckNfe, setCheckNfe] = useState([
        'Produtos por referência ou similar',
        'Sugestão de similares durante a venda',
        'Todos os similares de cada produto'
    ])
    const [Contacao, setContacao] = useState([
        'Pagamento de comissões de vendas  à vista',
        'Pagamento de comissões de vendas a prazo',
        'Pagamento de comissões de recebimento de contas'
    ])
    return (
        <>
        <Cabecalho/>
        <div id='div-autopecas-root'>
            <div id='autopecas-div-img'>
                
            </div>
            <div id='autopecas-div-img-titulo'>
                <p>Autopeças</p>
            </div>
            <div className="div-info-autopecas-line div-info-autopecas-line-color">
                <div className="div-info-autopecas-low-left">
                    <h1>Similaridade</h1>
                    {
                        CheckNfe.map((item) => 
                        <div className="list-check-autopecas">
                            <img src={IMGCheck}/>
                        <label>{item}</label>
                    </div>
                        )
                    }
                </div>
                <div className="div-info-autopecas-low-right">
                    
                        <img src={IMGautopecas} className='imgens-lines imgens-lines-box'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-autopecas-line">
                <div className="div-info-autopecas-low-left">
                    <h1>Comissões</h1>
                    {
                        Contacao.map((item) => 
                        <div className="list-check-autopecas">
                            <img src={IMGCheck}/>
                        <label>{item}</label>
                    </div>
                        )
                    }
                </div>
                <div className="div-info-autopecas-low-right">
                    
                        <img src={IMGautopecas2} className='imgens-lines imgens-lines-box'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-autopecas-line div-info-autopecas-line-color">
                <div className="div-info-autopecas-low-left">
                    <h1>Curva ABC</h1>
                    
                </div>
                <div className="div-info-autopecas-low-right">
                    
                        <img src={IMGCurvaABC} className='imgens-lines imgens-lines-box' id='autopecas-img-abc'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-autopecas-line div-info-autopecas-line22">
                <div className="div-info-autopecas-low-left">
                    <h1>DRE</h1>
                    
                </div>
                <div className="div-info-autopecas-low-right">
                    
                        <img src={IMGDRE} className='imgens-lines  imgens-lines-box' id='imgens-lines-dre'/>
                  
                    
                    
                        
                    
                </div>
            </div>
            
        </div>
        <div className='div-rodape'>
          <label>COPYRIGHT &copy; 2025 MegaSoftware Software House, Todos os direitos reservados. Fones: (67) 9 8111-6008 (67) 9 8162-5775</label>
       </div>
        </>
    )
}

export default AutoPecas