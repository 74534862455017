import React, { useEffect, useState, useRef } from "react";
import './style.css'
import Logo from '../../imagens/logo-nome.png'
import LogoIMG from '../../imagens/logo-principal.png'
import LogoMega from '../../imagens/logo-mega.jpg'
import { Connection } from "../connect";
import Load from '../../imagens/load_gif.gif'
import InputMask from 'react-input-mask';
import { ConnectionCotacao } from "../connect";
import { useParams } from "react-router-dom";
import { CurrencyInput } from 'react-currency-mask';

export const ProdutosCotacao = () => {
    const { id } = useParams()
    const [ ListaProdutos, setListaProdutos ] = useState([])
    const [ LoadV, setLoadV ] = useState(false)
    const [ LoadV2, setLoadV2 ] = useState(false)
    const [ StatusCotacao, setStatusCotacao ] = useState('')
    const inputRefs = useRef([]);

    const EnviarEmail = async() => {
        setLoadV2(true)
        const conn = new ConnectionCotacao()
        let dados_env = {
            'token': localStorage.getItem('token'),
            'cotacao': id,
         }
         
         let resp = await conn.Intranet('cotacao/enviar_email', dados_env)
         if (resp){
             
             if (resp.status == '0'){
                
             }
             if (resp.status == '2'){
                 window.location.href = '/cotacao/login'
             }
         }
         setLoadV2(false)
    }

    const Pesquisar = async () => {
            const conn = new ConnectionCotacao()
            let dados_env = {
               'token': localStorage.getItem('token'),
                'cotacao': id
            }
            let resp = await conn.Intranet('cotacao/consulta_produtos', dados_env)
            if (resp){
                
                if (resp.status == '0'){
                   setListaProdutos(resp.produtos)
                   setStatusCotacao(resp.status_cotacao)
                }
                if (resp.status == '2'){
                    window.location.href = '/cotacao/login'
                }
            }
            inputRefs.current = inputRefs.current.slice(0, resp.produtos.length);
        }
    
    useEffect(() => {Pesquisar()}, [])
    

    const FocusAutoProduto = async (pro_barr, valor) => {
       
        const conn = new ConnectionCotacao()
        let dados_env = {
            'token': localStorage.getItem('token'),
            'cotacao': id,
            'produtos': [{
                'pro_barr': pro_barr,
                'valor': valor
            }],
            'finalizar': false
        }
        console.log(dados_env)
        let resp = await conn.Intranet('cotacao/alterar_valor', dados_env)
        if (resp){
            
            if (resp.status == '0'){
                
            }
            if (resp.status == '2'){
                window.location.href = '/cotacao/login'
            }
        }
    }


    const Finalizar = async (pro_barr, valor) => {
        let listaTransformada = ListaProdutos.map(item => ({
            'pro_barr': item.dados.pro_barr,
            'valor': item.cotacao.valor
        }));

        const conn = new ConnectionCotacao()
        let dados_env = {
            'token': localStorage.getItem('token'),
            'cotacao': id,
            'produtos': listaTransformada,
            'finalizar': true
        }
        console.log(dados_env)
        let resp = await conn.Intranet('cotacao/alterar_valor', dados_env)
        if (resp){
            
            if (resp.status == '0'){
                window.location.href = '/cotacao/home'
            }
            if (resp.status == '2'){
                window.location.href = '/cotacao/login'
            }
        }
    }

    const handleKeyDown = (event, index) => {
        if (event.key === "Enter") {
            console.log(inputRefs)// Evita o comportamento padrão do formulário
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus(); // Foca no próximo input
            }
        }
    };

    const [VerVencedoras, setVerVencedoras] = useState(false)


    return(
        <>
        <div className="logo-topo-mega">
            <img src={LogoMega}/>
            <br/>
            <div className="telefone">
            <label>{"(34) 99642-9575 "}</label>
            <label>{"(67) 98111-6008"}</label>
            </div>
        </div>
        <div className="produtos-cotacao-div">
            <div className="produtos-cotacao-div-cont">
                <div className="button-voltar" onClick={() => window.location.href = '/cotacao/home'}> {"< anterior"}</div>
                <p>Produtos da cotação de n{'º'} <strong>{id}</strong>.</p>
                {
                    StatusCotacao == '2' ?
                    <div className="div-voce-venceu">
                        <div style={{width: '250px', cursor: 'pointer'}} onClick={() => setVerVencedoras(!VerVencedoras)}>
                            <p style={{margin: '0px'}}>
                                {
                                    VerVencedoras
                                    ?
                                    'Ver todas'
                                    :
                                    'Ver só as ofertas vencedoras'
                                }
                            </p>
                        </div>
                    </div>
                    :
                    ""

                }
                <br/>
                <div>
                    {
                        ListaProdutos.map((item, index) => {
                            if (StatusCotacao == '2' && VerVencedoras){
                                if (item.cotacao.ganhou){
                                return(
                                    <div className="div-list-produto-cotacao">
                                        {
                                            StatusCotacao == '2' ?
                                                !item.cotacao.empate ?
                                                item.cotacao.ganhou?
                                                    <div className="div-voce-venceu">
                                                        <div>
                                                            <p style={{margin: '0px'}}>OFERTA VENCEDORA!!</p>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="div-voce-perdeu">
                                                        <div>
                                                            <p style={{margin: '0px'}}>OFERTA SUPERADA!!</p>
                                                        </div>
                                                    </div>
                                            :
                                            <div className="div-voce-perdeu" >
                                                    <div style={{backgroundColor: 'gray'}}>
                                                        <p style={{margin: '0px'}}>EM ANÁLISE</p>
                                                    </div>
                                                </div>
                                            :
                                                ''
                                        }
                                        <div style={{display: 'inline-block', width: '70%', verticalAlign: 'middle'}}>
                                            
                                            <p>{item.dados.pro_barr} - {item.dados.pro_desc}</p>
                                        </div>
                                        <div style={{display: 'inline-block', width: '30%', verticalAlign: 'middle', paddingLeft: '10px', boxSizing: 'border-box', textAlign: 'center'}}>
                                            
                                            <p>{item.dados.qtd_compra} {item.dados.unidade}</p>
                                        </div>
                                        <hr/>
                                        <div style={{
                                            width: '100%',
                                            textAlign: 'center'
                                        }}>
                                            
                                            <CurrencyInput placeholder="Digite o preço"  className="input-padrao-cotacao" style={{width: '50%', textAlign: 'center', fontSize: '14pt'}}
                                            value={item.cotacao ? item.cotacao.valor : 0}
                                            onChangeValue={(event, originalValue, _) => {
                                               
                                                let produtos = ListaProdutos
                                                produtos[index]['cotacao'] = {
                                                    valor: originalValue,
                                                    ganhou: produtos[index]['cotacao']['ganhou']
                                                }
                                                setListaProdutos([...produtos])
                                            }}
                                            onBlur={() => {FocusAutoProduto(item.dados.pro_barr, ListaProdutos[index]['cotacao']['valor']);}}
                                            disabled={StatusCotacao == '2'}
                                            
                                            onKeyDown={(event) => handleKeyDown(event, index)}
                                            ref={(el) => inputRefs.current[index] = el}
                                            />
                                        </div>    
                                        
                                        
                                    </div>
                                )}
                            }
                            else {
                                return(
                                    <div className="div-list-produto-cotacao">
                                        {
                                            StatusCotacao == '2' ?
                                                !item.cotacao.empate ?
                                                item.cotacao.ganhou?
                                                    <div className="div-voce-venceu">
                                                        <div>
                                                            <p style={{margin: '0px'}}>OFERTA VENCEDORA!!</p>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="div-voce-perdeu">
                                                        <div>
                                                            <p style={{margin: '0px'}}>OFERTA SUPERADA!!</p>
                                                        </div>
                                                    </div>
                                            :
                                            <div className="div-voce-perdeu" >
                                                    <div style={{backgroundColor: 'gray'}}>
                                                        <p style={{margin: '0px'}}>EM ANÁLISE</p>
                                                    </div>
                                                </div>
                                            :
                                                ''
                                        }
                                        <div style={{display: 'inline-block', width: '70%', verticalAlign: 'middle'}}>
                                            
                                            <p>{item.dados.pro_barr} - {item.dados.pro_desc}</p>
                                        </div>
                                        <div style={{display: 'inline-block', width: '30%', verticalAlign: 'middle', paddingLeft: '10px', boxSizing: 'border-box', textAlign: 'center'}}>
                                            
                                            <p>{item.dados.qtd_compra} {item.dados.unidade}</p>
                                        </div>
                                        <hr/>
                                        <div style={{
                                            width: '100%',
                                            textAlign: 'center'
                                        }}>
                                            
                                            <CurrencyInput placeholder="Digite o preço"  className="input-padrao-cotacao" style={{width: '50%', textAlign: 'center', fontSize: '14pt'}}
                                            value={item.cotacao ? item.cotacao.valor : 0}
                                            onChangeValue={(event, originalValue, _) => {
                                               
                                                let produtos = ListaProdutos
                                                produtos[index]['cotacao'] = {
                                                    valor: originalValue,
                                                    ganhou: produtos[index]['cotacao']['ganhou']
                                                }
                                                setListaProdutos([...produtos])
                                            }}
                                            onBlur={() => {FocusAutoProduto(item.dados.pro_barr, ListaProdutos[index]['cotacao']['valor']);}}
                                            disabled={StatusCotacao == '2'}
                                            
                                            onKeyDown={(event) => handleKeyDown(event, index)}
                                            ref={(el) => inputRefs.current[index] = el}
                                            />
                                        </div>    
                                        
                                        
                                    </div>
                                )
                            }
                            
                        })
                    }
                </div>
                <div style={{
                    textAlign: 'center'
                }}>
                    <button className="button-padrao" onClick={Finalizar} disabled={StatusCotacao == '2'}>
                    {
                        LoadV?
                        <img src={Load} style={{width: '15px'}}/>
                        : 'Finalizar'
                    }
                    </button>

                    <br/>

                    <button className="button-padrao" onClick={EnviarEmail} disabled={StatusCotacao == '1'} style={{
                        width: '50%',
                        background: 'gray',
                        marginTop: '20px',
                        display: 'inline-block'
                    }}>
                    {
                        LoadV2?
                        <img src={Load} style={{width: '15px'}}/>
                        : 'Enviar Cotação por Email'
                    }
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}