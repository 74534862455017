import React, { useEffect, useRef, useState } from "react";
import './style.css'
import Logo from '../../imagens/logo-nome.png'
import LogoIMG from '../../imagens/logo-principal.png'
import LogoMega from '../../imagens/logo-mega.jpg'
import { Connection } from "../connect";
import Load from '../../imagens/load_gif.gif'
import InputMask from 'react-input-mask';
import { ConnectionCotacao } from "../connect";



export const LoginCotacao = () => {
    const [ ListaEmpresas, setListaEmpresas ] = useState([])
    const [ EmpresaSelect, setEmpresaSelect ] = useState('')
    const [ CNPJ, setCNPJ ] = useState('')
    const [ Senha, setSenha ] = useState('')
    const CNPJInput = useRef()
    const [ SenhaInput, setSenhaInput ] = useState()
    const [LoadV, setLoadV] = useState(false)
    const [SenhaIncorreta, setSenhaIncorreta] = useState(false)


    const Pesquisar = async () => {
       
        
    }

    useEffect(() => { Pesquisar()}, [])

    const Entrar = async () => {
        if (true){
            if (!LoadV){
                setLoadV(true)
                const conn = new ConnectionCotacao()
               
                let resp = await conn.Intranet('cotacao/login', {empresa: EmpresaSelect, cnpj: CNPJ, senha: Senha})
    
                if (resp){
                    if (resp.status == '0'){
                        if (resp.vendedor){
                            localStorage.setItem('token', resp.token)
                            localStorage.setItem('empresa', EmpresaSelect)
                    
                            window.location.href = '/cotacao/home'
                        }else {
                            localStorage.setItem('_token', resp.token)
                            window.location.href = '/cotacao/correcao'
                        }
                        
                    }
                    else if(resp.status == '2'){
                        setSenhaIncorreta(true)
                    }
                }
                setLoadV(false)
            }
        }
        
    }


    return (
        <>
        <div className="logo-topo-mega">
            <img src={LogoMega}/>
            <div className="telefone">
            <label>{"(34) 99642-9575 "}</label>
            <label>{"(67) 98111-6008"}</label>
            </div>
        </div>

        <div class="center-login">
            <h1 id="titulo-login">Cotação Online</h1>
            <div>
                <p id="info-p-login">Informe os dados abaixo para ingressar no sistema.</p>
            </div>
            <div>
                
                <InputMask 
                    className="input-padrao-cotacao" 
                    placeholder="Digite o CNPJ do estabelecimento" 
                    mask="99.999.999/9999-99" 
                    maskChar=" " 
                    value={EmpresaSelect} 
                    onChange={(event) => setEmpresaSelect(event.target.value)}
                    onKeyUp={(event) => {
                        if (event.key == 'Enter') {
                           
                            CNPJInput.focus()
                        }
                    }}
                    />
                <InputMask 
                    className="input-padrao-cotacao" 
                    placeholder="Digite o seu CNPJ" 
                    mask="99.999.999/9999-99" 
                    maskChar=" " 
                    value={CNPJ} 
                    onChange={(event) => setCNPJ(event.target.value)}
                    inputRef={CNPJInput}
                    onKeyUp={(event) => {
                        if (event.key == 'Enter') {
                            SenhaInput.focus()
                        }
                    }}
                    
                    />
                <input 
                    className="input-padrao-cotacao" 
                    placeholder="Digite sua senha" 
                    type="password" 
                    value={Senha} 
                    onChange={(event) => setSenha(event.target.value)} 
                    ref={setSenhaInput}
                    onKeyUp={(event) => {
                        if (event.key == 'Enter') {
                            Entrar()
                        }
                    }}
                    />
            </div>
            <p style={{width: '100%', textAlign: 'center', fontSize: '10pt', color: 'red', margin: '0px'}}
                        >{SenhaIncorreta?'Usuário ou senha incorreto.' : ' '}</p>
            <button className="button-padrao" onClick={Entrar}>
                {
                      LoadV?
                      <img src={Load} style={{width: '15px'}}/>
                      : 'Entrar'
                }
            </button>
        </div>
        </>
    )
}

