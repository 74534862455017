import React from "react";
import './sobre.css'
import IMGQuemSomos from '../imagens/quem-somos.jpg'
import Cabecalho from "../cabecalho/cabecalho";

const Sobre = (props) => {
    return (
        <>
        <Cabecalho/>
        
        <div id='div-root-quem-somos'>
        <div id='div-img-quem-somos'>
            <div id='div-label-quem-somos-img'>
                <label></label>
            </div>
            
        </div>
        <div id='div-texto-sobre-nos'>
            <h1>Sobre Nós <br/><br/>Surgimos para facilitar a gestão empresarial</h1>
            <hr/>
            <p>
                A MegaSoftware  é uma empresa de tecnologia que atua no mercado há  mais de 28 anos no desenvolvimento de sistemas, com credibilidade de seus clientes por seu compromisso com os resultados. Procuramos agregar valores com alta qualidade baseadas nas mais modernas tecnologias de informática.
            </p>
        </div>
        </div>
        <div className='div-rodape'>
          <label>COPYRIGHT &copy; 2025 MegaSoftware Software House, Todos os direitos reservados. Fones: (67) 9 8111-6008 (67) 9 8162-5775</label>
       </div>
        </>
    )
}

export default Sobre