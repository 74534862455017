import React, { useEffect, useState, useRef } from "react";
import './style.css'
import Logo from '../../imagens/logo-nome.png'
import LogoIMG from '../../imagens/logo-principal.png'
import LogoMega from '../../imagens/logo-mega.jpg'
import { Connection } from "../connect";
import Load from '../../imagens/load_gif.gif'
import InputMask from 'react-input-mask';
import { ConnectionCotacao } from "../connect";
import { useParams } from "react-router-dom";
import { CurrencyInput } from 'react-currency-mask';


export const ProdutosCotacaoCorrigir = () => {
    const { id } = useParams()
    const [ ListaProdutos, setListaProdutos ] = useState([])
    const [ LoadV, setLoadV ] = useState(false)
    const [ StatusCotacao, setStatusCotacao ] = useState('')
    const inputRefs = useRef([]);

    const Pesquisar = async () => {
            const conn = new ConnectionCotacao()
            let dados_env = {
               'token': localStorage.getItem('_token'),
                'cotacao_id': String(id),
                'formato': '2'
            }
            let resp = await conn.Intranet('cotacao/consulta_correcao', dados_env)
            if (resp){
                
                if (resp.status == '0'){
                   setListaProdutos(resp.produtos)
                   setStatusCotacao(resp.status_cotacao)
                }
                if (resp.status == '2'){
                    //window.location.href = '/cotacao/login'
                }
            }
            inputRefs.current = inputRefs.current.slice(0, resp.produtos.length);
        }
    
    useEffect(() => {Pesquisar()}, [])
    

    const FocusAutoProduto = async (id, valor) => {
       
        const conn = new ConnectionCotacao()
        let dados_env = {
            'token': localStorage.getItem('_token'),
            'id': String(id),
            'valor': String(valor)
        }
        console.log(dados_env)
        let resp = await conn.Intranet('cotacao/corrigir_preco', dados_env)
        if (resp){
            
            if (resp.status == '0'){
                
            }
            if (resp.status == '2'){
                //window.location.href = '/cotacao/login'
            }
        }
    }

    const Desempate = async (id_produto, pro_barr) => {
       
        const conn = new ConnectionCotacao()
        let dados_env = {
            'cnpj': localStorage.getItem('empresa'),
            'cotacao': String(id),
            'pro_barr': String(pro_barr),
            'id': String(id_produto)
        }
        console.log(dados_env)
        let resp = await conn.Intranet('desempate', dados_env)
        if (resp){
            
            if (resp.status == '0'){
                Pesquisar()
            }
            if (resp.status == '2'){
                //window.location.href = '/cotacao/login'
            }
        }
    }


    

    const handleKeyDown = (event, index) => {
        if (event.key === "Enter") {
            console.log(inputRefs)// Evita o comportamento padrão do formulário
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus(); // Foca no próximo input
            }
        }
    };
    


    return(
        <>
        <div className="logo-topo-mega">
            <img src={LogoMega}/>
            <div className="telefone">
            <label>{"(34) 99642-9575 "}</label>
            <label>{"(67) 98111-6008"}</label>
            </div>
        </div>
        <div className="produtos-cotacao-div">
            <div className="produtos-cotacao-div-cont">
                <div className="button-voltar" onClick={() => window.location.href = '/cotacao/correcao'}> {"< anterior"}</div>
                <p>Produtos da cotação de n{'º'} <strong>{id}</strong>.</p>
                <div>
                    {
                        ListaProdutos.map((item, index) => {
                            return(
                                <div className="div-list-produto-cotacao">
                                   
                                    <div style={{display: 'inline-block', width: '70%', verticalAlign: 'middle'}}>
                                        
                                        <p>{item.pro_barr} - {item.pro_desc}</p>
                                    </div>
                                    
                                    <hr/>
                                    <div style={{
                                        width: '100%',
                                        textAlign: 'center'
                                    }}>
                                        {
                                            item.valores.map( (item2, index2) => (
                                                <div>
                                                    <div style={{
                                                        width: '50%',
                                                        maxWidth: '40%',
                                                        display: 'inline-block',
                                                        textAlign: 'left'
                                                    }}>
                                                        <p>{item2.razao_social}</p>
                                                    </div>
                                                    <div style={{
                                                        width: '15%',
                                                        maxWidth: '15%',
                                                        display: 'inline-block',
                                                        textAlign: 'left'
                                                    }}>
                                                        {
                                                            item2.empate?
                                                                <div style={{
                                                                    backgroundColor: 'gray',
                                                                    textAlign: 'center',
                                                                    padding: '5px',
                                                                    color: 'white',
                                                                    borderRadius: '5px'

                                                                }} onClick={() => Desempate(item2.id, item.pro_barr)}>
                                                                    <p style={{
                                                                        margin: '0px'
                                                                    }}>vencedor</p>
                                                                </div>
                                                            :
                                                            item2.ganhou?
                                                            <div style={{
                                                                backgroundColor: 'green',
                                                                textAlign: 'center',
                                                                padding: '5px',
                                                                color: 'white',
                                                                borderRadius: '5px'

                                                            }}>
                                                                <p style={{
                                                                    margin: '0px'
                                                                }}>vencedor</p>
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                    <div style={{
                                                        width: '35%',
                                                        maxWidth: '35%',
                                                        display: 'inline-block'
                                                    }}>
                                                        <CurrencyInput placeholder="Digite o preço"  className="input-padrao-cotacao" style={{width: '50%', textAlign: 'center'}}
                                                            value={item2.valor}
                                                            onChangeValue={(event, originalValue, _) => {
                                                            
                                                                let produtos = ListaProdutos
                                                                produtos[index]['valores'][index2]["valor"] = originalValue
                                                                setListaProdutos([...produtos])
                                                            }}
                                                            onBlur={() => {FocusAutoProduto(item2.id, ListaProdutos[index]['valores'][index2]["valor"]);}}
                                                            disabled={StatusCotacao == '1'}
                                                            
                                                            onKeyDown={(event) => handleKeyDown(event, index)}
                                                            ref={(el) => inputRefs.current[index] = el}
                                                            />
                                                    </div>
                                                </div>
                                            )
                                        )
                                        }
                                        
                                    </div>    
                                    
                                    
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <button className="button-padrao"  onClick={() => window.location.reload()}>
                    {
                        LoadV?
                        <img src={Load} style={{width: '15px'}}/>
                        : 'RECARREGAR'
                    }
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}